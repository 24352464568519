import React, { useEffect } from 'react';

import { isKorean } from '@/util';
import { postUrl } from '@/constant';

const PostRedirection: React.FC = () => {
  useEffect(() => {
    window.location.href = isKorean ? postUrl.KO : postUrl.EN;
  }, []);

  return <div />;
};

export default PostRedirection;
